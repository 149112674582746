import { io } from "socket.io-client";
import Vue from "vue";
import utils from "@/utils/utils";

class SocketioService {
	constructor() {}

	emisor = new Vue();
	callRoom = "";

	setupSocketConnection(interviewer_id) {
		let socket = io(`${utils.httpProtocol}://${utils.host}:${utils.port}`);

		this.sendInterviewerId(socket, interviewer_id);

		socket.on("add-interviewer", (data) => {
			Vue.prototype.$socket_id = data.socketId;
		});

		socket.on("add-candidate", (data) => {
			Vue.prototype.$socket_id = data.socketId;
		});

		socket.on("get-notifications", (data) => {
			this.emisor.$emit(
				"notificationsSended",
				data.map((msg) => JSON.parse(msg))
			);
		});

		socket.on("cv-completed", (data) => {
			this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
		});

		socket.on("cv-error", (data) => {
			this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
		});

		socket.on("dismiss-notification", (data) => {
			this.emisor.$emit("notificationDismissed", data.response);
		});

		socket.on("call-room", (data) => {
			this.emisor.$emit("callRoomResponse", data);
		});

		socket.on("call-open", (data) => {
			this.emisor.$emit("callOpenResponse", data);
		});

		socket.on("call-close", (data) => {
			this.emisor.$emit("callCloseResponse", data);
		});

		socket.on("call-user-join", (data) => {
			this.emisor.$emit("callUserJoinResponse", data);
		});

		socket.on("call-user-opt", (data) => {
			this.emisor.$emit("callUserOptResponse", data);
		});

		socket.on("chat-user-message", (data) => {
			this.emisor.$emit("callUserChatMsgResponse", data);
		});

		socket.on("call-user-leave", (data) => {
			this.emisor.$emit("callUserLeaveResponse", data);
		});

		socket.on("call-user-finished", (data) => {
			this.emisor.$emit("callUserFinishedResponse", data);
		});

		return socket;
	}

	disconnect(socket) {
		if (socket) {
			socket.disconnect();
		}
	}

	sendInterviewerId(socket, interviewer_id) {
		socket.emit("add-interviewer", { interviewer: interviewer_id });
	}

	sendCandidateId(socket, candidate_id) {
		socket.emit("add-candidate", { candidate: candidate_id });
	}

	dismissNotification(socket, index) {
		socket.emit("dismiss-notification", { index: index });
	}

	createCallRoom(socket, call_id) {
		socket.emit("call-room", { callId: call_id });
	}

	joinCall(socket, peer_id, camera, micro) {
		socket.emit("call-join", {
			peer_id: peer_id,
			video: camera,
			audio: micro,
			serializations: ["binary", "binary-utf8", "json"],
		});
	}

	leaveCall(socket, isCandidate) {
		socket.emit("call-leave");
		if (!isCandidate) {
			socket.emit("call-finished"); // AQUI SE ENVIA PARA ACABAR LA ENTREVISTA
		}

		return true;
	}

	callOptions(socket, camera, micro) {
		socket.emit("call-opt", { video: camera, audio: micro, hand: false });
	}

	chatMessage(socket, message, timeSended) {
		socket.emit("call-chat-msg", { content: message, timeSended: timeSended });
	}
}

export default new SocketioService();
