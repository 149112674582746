import { Socket } from "socket.io-client";
import http from "../http-common";
const API_URL_PATH = "videos";

class VideosDataService {
	uploadVideo(media, socketId, callId, candidateId) {
		const handlerKeys = {
			audio: {
				candidate: "audio_candidate",
				interviewer: "audio_interviewer",
			},
			video: {
				candidate: "video_candidate",
				interviewer: "video_interviewer",
			},
		};

		const form = new FormData();

		media.forEach((res) => {
			if (handlerKeys[res.type][res.source] !== "video_interviewer")
				form.append(handlerKeys[res.type][res.source], res.blob);
		});

		form.append("call_id", callId);
		form.append("socket_id", socketId);
		form.append("candidate_id", candidateId);

		const headers = {
			"Content-Type": "multipart/form-data",
		};

		return http.post(API_URL_PATH, form, { headers: headers });
	}
}

export default new VideosDataService();