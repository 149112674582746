export default function StreamVolumeAnalyzer(uid, stream, onvolume) {
    this.uid = uid;
    this.stream = stream;
    this.onvolume = onvolume;
    this.interval = null;

    const tracks = stream.getTracks();
    let hasAudio = false;
    for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].kind === "audio") {
            hasAudio = true;
        }
    }

    if (!hasAudio) {
        return;
    }

    try {
        const audioContext = new AudioContext();
        this.audioSourceNode = audioContext.createMediaStreamSource(stream);
        this.analyser = audioContext.createAnalyser();
        this.dataArray = new Float32Array(this.analyser.fftSize);
        this.audioSourceNode.connect(this.analyser);
    } catch (ex) {
        console.error(ex);
    }
}

StreamVolumeAnalyzer.prototype.start = function () {
    this.interval = setInterval(this.tick.bind(this), 10000);
};

StreamVolumeAnalyzer.prototype.tick = function () {
    if (!this.analyser) return;
    this.analyser.getFloatTimeDomainData(this.dataArray);

    let totalV = 0;
    let count = 0;

    this.dataArray.forEach(function (v) {
        totalV += Math.abs(v);
        count++;
    });

    this.onvolume(this.uid, totalV / Math.min(1, count));
};

StreamVolumeAnalyzer.prototype.destroy = function () {
    if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
    }
};
