import http from "../http-common";

const API_URL_PATH = 'candidates';

class CandidateDataService{
    
    getCandidateInfo(candidateId, interviewId, username, email) {
        const body = { candidateId: candidateId, interviewId: interviewId, username: username, email: email}
        return http.post(API_URL_PATH, body)
    }

}
export default new CandidateDataService();

