import http from "../http-common";

const API_URL_PATH_UPLOAD = 'upload/cvs';

class CVsDataService {
    upload(files, onUploadCVs) {
        return http.post(API_URL_PATH_UPLOAD, files, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadCVs
        })
    }

    findAll() {
        return http.get(API_URL_PATH_UPLOAD)
    }

    getCV(id) {
        return http.get(API_URL_PATH_UPLOAD + '/' + id)
    }

    
    deleteCV(id) {
        return http.delete(API_URL_PATH_UPLOAD + '/' + id)
    }
}

export default new CVsDataService();