import Vue from 'vue'
import VueRouter from 'vue-router'

import LandingPage from '../views/LandingPage.vue'
import CVList from '../views/CVList.vue'
import CVResults from '../views/CVResults.vue'
import UploadCV from '../views/UploadCV.vue'
import AccessInterview from '../views/AccessInterview.vue'
import AccessInterviewCandidate from '../views/AccessInterviewCandidate.vue'
import Interview from '../views/Interview.vue'
import InterviewResults from '../views/InterviewResults.vue'
import Error from '../views/ErrorPage.vue'

// Vue Upload
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

// MDI Icons
import '@mdi/font/css/materialdesignicons.min.css';

// Moment
import moment from 'moment'

Vue.use(VueRouter)
Vue.use(VueFileAgent);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
});

Vue.filter('slice20Characters', function(value) {
  if (value) {
    return value.length >= 20 ? value.slice(0, 20) + '...' : value
  }
});

Vue.filter('slice40Characters', function(value) {
  if (value) {
    return value.length >= 40 ? value.slice(0, 40) + '...' : value
  }
});

const routes = [
  { path: '/', name: 'home', component: LandingPage },
  { path: '/cv-list', name: 'cv list', component: CVList },
  { path: '/cv-results', name: 'cv results', component: CVResults },
  { path: '/upload-cv', name: 'upload cv', component: UploadCV },
  { path: '/access-interview', name: 'access interview', component: AccessInterview },
  { path: '/access-interview-candidate', name: 'access interview candidate', component: AccessInterviewCandidate },
  { path: '/interview', name: 'interview', component: Interview },
  { path: '/interview-results', name: 'interview results', component: InterviewResults },
  { path: '/error', name: 'error', component: Error }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router