<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <h1>Información personal</h1>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="1">
                      <v-img v-if="personalInformation.image == null" :aspect-ratio="1" class="bg-white"
                        :src="require('@/assets/user.svg')" width="100" />
                      <v-img v-else :aspect-ratio="1" class="bg-white" :src="'https://d1wsk4j49gqdtn.cloudfront.net/' + personalInformation.image"
                        width="100" />
                    </v-col>
                    <v-col cols="10">
                      <p v-if="personalInformation.name !== null">
                        Name: {{ personalInformation.name }}</p>
                      <p v-else>Name: N/D</p>
                      <p v-if="personalInformation.email !== null">
                        Email: {{ personalInformation.email }}</p>
                      <p v-else>Email: N/D</p>
                      <p v-if="personalInformation.phone !== null">
                        Phone: {{ personalInformation.phone }}</p>
                      <p v-else>Phone: N/D</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12">
                <v-card-title class="pt-0">
                  <h1>Información académica</h1>
                </v-card-title>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card-text class="pb-0">
                      <v-data-table :headers="headersEducation" :items="education" multi-sort
                        :options="{ itemsPerPage: 5 }" v-if="education.length > 0">
                        <template v-slot:[itemTitle]="{ item }">
                          <template v-if="item.title != undefined">
                            {{ item.title }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemInstitution]="{ item }">
                          <template v-if="item.institution != undefined">
                            {{ item.institution }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemDate]="{ item }">
                          <template v-if="item.date != undefined">
                            {{ item.date }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                      </v-data-table>
                      <p v-else>Sin información académica</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12">
                <v-card-title class="pt-0">
                  <h1>Experiencia laboral</h1>
                </v-card-title>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card-text class="pb-0">
                      <v-data-table :headers="headersWorkExperience" :items="workExperience" multi-sort
                        :options="{ itemsPerPage: 5 }" v-if="workExperience.length > 0">
                        <template v-slot:[itemPosition]="{ item }">
                          <template v-if="item.position != undefined">
                            {{ item.position }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemCompany]="{ item }">
                          <template v-if="item.company != undefined">
                            {{ item.company }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemPlace]="{ item }">
                          <template v-if="item.place != undefined">
                            {{ item.place }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemFunctions]="{ item }">
                          <template v-if="item.functions != undefined">
                            <v-card-text class="d-flex align-center">
                              {{ item.functions.length }}
                              <v-btn v-on="on" icon class="ml-2 more-info-btn" :ripple="false" @click="openFunctions(item)"> <v-icon size="20"
                                  color="primary">mdi-information</v-icon> </v-btn>
                            </v-card-text>
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemDuration]="{ item }">
                          <template v-if="item.duration != undefined">
                            {{ item.duration }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                        <template v-slot:[itemDate]="{ item }">
                          <template v-if="item.date != undefined">
                            {{ item.date }}
                          </template>
                          <template v-else>
                            <p>-</p>
                          </template>
                        </template>
                      </v-data-table>
                      <p v-else>Sin experiencia laboral</p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12">
                <v-card-title class="pt-0 mb-4">
                  <h1>Skills</h1>
                </v-card-title>
                <v-row class="mb-4">
                  <v-col cols="12" class="py-0">
                    <v-card-text class="py-0">
                      <v-row v-if="skills.length > 0">
                        <v-col class="col col-4 py-0" v-for="(skill, index) in skills" :key="index">
                          <v-list color="transparent" class="list-container">
                            <v-list-item class="list-items-class">
                              <v-list-item-icon class="py-0 mb-0 mr-2 list-items-icon-class">
                                <v-icon>mdi-circle-small</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <v-list-item-title>{{ skill.name }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <p class="ml-3">Sin skills</p>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12">
                <v-card-title class="pt-0 mb-4">
                  <h1>Languages</h1>
                </v-card-title>
                <v-row class="mb-4">
                  <v-col cols="12" class="py-0">
                    <v-card-text class="py-0">
                      <v-row v-if="languages.length > 0">
                        <v-col class="col col-4 py-0" v-for="(language, index) in languages" :key="index">
                          <v-list color="transparent" class="list-container">
                            <v-list-item class="list-items-class">
                              <v-list-item-icon class="py-0 mb-0 mr-2 list-items-icon-class">
                                <v-icon>mdi-circle-small</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <v-list-item-title>{{ language.lang }} ({{ language.level }})</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <p class="ml-3">Sin idiomas</p>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      <v-dialog v-model="showFunctions" max-width="550" min-height="550">
        <v-card>
          <v-card-title>
            <h5>Funciones</h5>
          </v-card-title>
          <v-card-text v-if="itemToShow.functions !== null && itemToShow.functions !== undefined" class="pb-0">
            <v-list color="transparent">
              <v-list-item v-for="(func, index) in itemToShow.functions" :key="index">
                <v-list-item-icon>
                  <v-icon>mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <p class="mb-0">{{ func }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="showFunctions = false" class="ok-modal"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

        <v-alert class="mt-4" v-if="message" outlined :color="successfull ? 'success' : 'error'"
          :icon="successfull ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
          {{ message }}
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CVsDataService from "@/services/CVsDataService";

export default {
  name: 'CVResults',
  data() {
    return {
      message: '',
      successfull: false,
      personalInformation: {
        image: null
      },
      education: [],
      workExperience: [],
      certificates: [],
      skills: [],
      languages: [],
      headersEducation: [
        { text: 'Título', value: 'title' },
        { text: 'Institución', value: 'institution' },
        { text: 'Fecha', value: 'date' }
      ],
      headersWorkExperience: [
        { text: 'Posición', value: 'position' },
        { text: 'Empresa', value: 'company' },
        { text: 'Ciudad', value: 'place' },
        { text: 'Funciones', value: 'functions' },
        { text: 'Duración', value: 'duration' },
        { text: 'Fecha', value: 'date' }
      ],
      itemToShow: {},
      showFunctions: false
    }
  },
  computed: {
    itemTitle() {
      return `item.title`
    },
    itemInstitution() {
      return `item.institution`
    },
    itemDate() {
      return `item.date`
    },
    itemPosition() {
      return `item.position`
    },
    itemCompany() {
      return `item.company`
    },
    itemPlace() {
      return `item.place`
    },
    itemFunctions() {
      return `item.functions`
    },
    itemDuration() {
      return `item.duration`
    },
  },
  mounted() {
    this.getCV()
  },
  methods: {
    getCV() {
      if (this.$route.params.cv !== undefined && this.$route.params.cv !== null) {
        CVsDataService.getCV(this.$route.params.cv.candidate_id).then(
          response => {
            if (response.status == 200) {
              console.log('response.data.data: ', response.data.data)
              this.personalInformation.name = response.data.data['personal_information']['Name'] || response.data.data['personal_information']['name'] || null
              this.personalInformation.email = response.data.data['personal_information']['email'] || null
              this.personalInformation.links = response.data.data['personal_information']['links'] || null
              this.personalInformation.phone = response.data.data['personal_information']['phone'] || null
              if (this.personalInformation.phone !== null && Array.isArray(this.personalInformation.phone))
                this.personalInformation.phone = this.personalInformation.phone[0]
              this.personalInformation.image = response.data.data.image_path || null

              if (response.data.data['education'] !== undefined) {
                response.data.data['education'].forEach(item => {
                  let education = {}
                  education.date = item['date'] || null
                  education.institution = item['academic_institution'] || null
                  education.title = item['academic_title'] || null

                  this.education.push(education)
                })
              }

              if (response.data.data['work_experience'] !== undefined) {
                response.data.data['work_experience'].forEach(item => {
                  let job = {}
                  job.position = item['position'] || null
                  job.functions = item['functions'] || null
                  job.company = item['company'] || null
                  job.date = item['date'] || null
                  job.place = item['city_country'] || null
                  job.duration = item['duration'] || null

                  this.workExperience.push(job)
                })
              }

              if (response.data.data['tools_and_skills'] !== undefined) {
                response.data.data['tools_and_skills'].forEach(item => {
                  let skill = {}
                  
                  skill.level = item['level'] || null

                  if (item['skill'] !== undefined) {
                    skill.name = item['skill'] || null
                    this.skills.push(skill)
                  }
                  if (item['lang'] !== undefined) {
                    skill.lang = item['lang'] || null
                    this.languages.push(skill)
                  }

                })
              }

              // TODO: Certificates

              console.log('this.personalInformation: ', this.personalInformation)
              console.log('this.education: ', this.education)
              console.log('this.workExperience: ', this.workExperience)
              console.log('this.skills: ', this.skills)
              // console.log('this.certificates: ', this.certificates)
            } else {
              this.successfull = false
              this.message = 'Ha ocurrido un error recuperando la información del CV. Por favor, inténtelo más tarde.'
            }
          },
          error => {
            this.successfull = false
            this.message = 'Ha ocurrido un error recuperando la información del CV. Por favor, inténtelo más tarde.'
            console.error((error.response && error.response.data) ||
              error.message ||
              error.toString())
          }
        )
      } else {
        this.$router.push('/cv-list');
      }
    },
    openFunctions(item) {
      this.showFunctions = true
      this.itemToShow = {...item}
      console.log('itemToShow: ', this.itemToShow)
    }
  }
}
</script>
