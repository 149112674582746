import http from "../http-common";

const API_URL_PATH = 'interviews';

class InterviewDataService {

  createInterview(interview) {
    return http.post(API_URL_PATH, interview)
  }

  editInterview(interview) {
    return http.put(API_URL_PATH + '/' + interview.id, interview)
  }
    
  deleteInterview(id, candidateId, mmrId, reason) {
    return http.delete(API_URL_PATH + '/' + id, { data: { candidate_id: candidateId, mmr_id: mmrId, reason: reason } })
  }

  registerCall(candidate_id, interviewer_id, interview_id) {
    return http.get(API_URL_PATH + '/call?candidateId=' + candidate_id + '&interviewerId=' + interviewer_id + '&interviewId=' + interview_id)
  }

  getInterviewInfo(interviewId) {
    return http.get(API_URL_PATH + `/${interviewId}`)
  }

  resendMail(interview_id) {
    return http.get(API_URL_PATH + '/resendMail?&interviewId=' + interview_id)
  }

}

export default new InterviewDataService();