<template>
  <v-app>
    <v-app-bar class="topbar" fixed
      v-if="currentRouteName !== 'interview' && currentRouteName !== 'access interview candidate' && currentRouteName !== 'error'">
      <v-img src="img/logo-virtualProfiler.png" max-height="60" max-width="90" contain alt="Logo"></v-img>
      <v-spacer></v-spacer>
      <v-menu v-model="menu" offset-y v-if="notifications.length > 0" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="mr-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon size="20" color="primary" v-on="on">mdi-bell-badge</v-icon>
              </template>
              <span>Notificaciones</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(notification, index) in notifications" :key="index">
            <v-card class="notification-card"
              :class="notification.status === 'SUCCESS' ? 'notification-success' : 'notification-error'" elevation="1">
              <v-list-item-content class="px-2">
                <v-row v-if="notification.type === 'CV'">
                  <v-col cols="11">
                    <p v-if="notification.status === 'SUCCESS'" class="mb-0 notification-link"
                      @click="openCV(notification, index)">El CV de <b>{{ notification.username }}</b>
                      ha sido
                      analizado con éxito ({{ notification.date }})</p>
                    <p v-else class="mb-0">Error analizando el CV de <b>{{ notification.username ?
        notification.username : 'Usuario Desconocido' }}</b> ({{ notification.date }})
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <v-icon size="18" :color="notification.status === 'SUCCESS' ? 'green' : 'red'"
                      @click="dismissNotification(index)" class="mr-2">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="11">
                    <template v-if="notification.status === 'SUCCESS'">
                      <p v-if="notification.reason === 'Audio Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">El audio de la entrevista de <b>{{
        notification.username }}</b> ha sido analizado con éxito ({{ notification.date }})</p>
                      <p v-if="notification.reason === 'Tone Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">El tono de la entrevista de <b>{{
        notification.username }}</b> ha sido analizado con éxito ({{ notification.date }})</p>
                      <p v-if="notification.reason === 'Video Analyzed'" class="mb-0 notification-link"
                        @click="openInterview(notification.id, index)">El video de la entrevista de <b>{{
        notification.username }}</b> ha sido analizado con éxito ({{ notification.date }})</p>
                    </template>
                    <p v-else class="mb-0">Error analizando la entrevista de <b>{{ notification.username
        ?
        notification.username : 'Usuario Desconocido' }}</b> ({{ notification.date }})
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <v-icon size="18" :color="notification.status === 'SUCCESS' ? 'green' : 'red'"
                      @click="dismissNotification(index)" class="mr-2">mdi-close</v-icon>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-card>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon disabled class="mr-1"> <v-icon size="20" color="primary">mdi-bell</v-icon> </v-btn>
        </template>
        <span>Notificaciones</span>
      </v-tooltip>
      <v-tooltip bottom v-if="currentRouteName !== 'home'">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="mr-1" @click="goBack()"> <v-icon size="20"
              color="primary">mdi-arrow-left</v-icon> </v-btn>
        </template>
        <span>Atrás</span>
      </v-tooltip>
    </v-app-bar>
    <v-main class="main-top">
      <router-view />
    </v-main>
  </v-app>
</template>


<script>
import SocketioService from '@/services/socketioService.js'
import Vue from 'vue'

export default {
  name: 'App.vue',
  data() {
    return {
      notifications: [],
      menu: false
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted() {
    const interviewer_id = '98634592835'; //Hardcodeado con valor aleatorio

    const socket = SocketioService.setupSocketConnection(interviewer_id);

    Vue.prototype.$socket = socket;

    SocketioService.emisor.$on('notificationsSended', (data) => {
      this.notifications = data;
    })

    SocketioService.emisor.$on('notificationsActiveSended', (data) => {
      this.notifications.splice(0, 0, data)
      this.menu = true
    })

    SocketioService.emisor.$on('notificationDismissed', (data) => {
      this.notifications.splice(data, 1);
    })
  },
  methods: {
    dismissNotification(index) {
      SocketioService.dismissNotification(this.$socket, index);
    },
    goBack() {
      if (this.currentRouteName === 'cv list' || this.currentRouteName === 'upload cv') this.$router.push('/');
      else if (this.currentRouteName === 'access interview' || this.currentRouteName === 'cv results' || this.currentRouteName === 'interview results') this.$router.push('/cv-list');
    },
    openCV(cv, index) {
      this.$router.push({ name: 'cv results', params: { cv: cv } });
      this.menu = false
      this.dismissNotification(index)
    },
    openInterview(interview, index) {
      this.$router.push({ name: 'interview results', params: { interview: interview } });
      this.menu = false
      this.dismissNotification(index)
    }
  }
}
</script>