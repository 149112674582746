<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <h1>Candidatos</h1>
                </v-card-title>

                <v-alert v-if="messageCreateInterview" outlined
                  :color="successfullCreateInterview ? 'success' : 'error'"
                  :icon="successfullCreateInterview ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageCreateInterview }}
                </v-alert>
                <v-alert v-if="messageDeleteCandidate" outlined
                  :color="successfullDeleteCandidate ? 'success' : 'error'"
                  :icon="successfullDeleteCandidate ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageDeleteCandidate }}
                </v-alert>
                <v-alert v-if="messageEditInterview" outlined :color="successfullEditInterview ? 'success' : 'error'"
                  :icon="successfullEditInterview ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageEditInterview }}
                </v-alert>
                <v-alert v-if="messageDeleteInterview" outlined
                  :color="successfullDeleteInterview ? 'success' : 'error'"
                  :icon="successfullDeleteInterview ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageDeleteInterview }}
                </v-alert>
                <v-alert v-if="messageRegisterInterview" outlined
                  :color="successfullRegisterInterview ? 'success' : 'error'"
                  :icon="successfullRegisterInterview ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageRegisterInterview }}
                </v-alert>
                <v-alert v-if="messageResendMail" outlined :color="successfullResendMail ? 'success' : 'error'"
                  :icon="successfullResendMail ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                  {{ messageResendMail }}
                </v-alert>
                <v-data-table :headers="headers" :items="cvs" item-key="id" :loading="loading"
                  loading-text="Cargando... Por favor, espere" multi-sort show-expand single-expand
                  :expanded.sync="expanded">
                  <template v-slot:[`item.cv_extraction_status`]="{ item }">
                    <v-chip :color="getColor(item.cv_extraction_status)">
                      {{ item.cv_extraction_status }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    {{ item.date | formatDate }}
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-4">
                      <v-card-title class="pt-0">
                        <h4>Videoentrevistas</h4>
                      </v-card-title>
                      <v-data-table :headers="headersInterviews" :items="item.interviews" multi-sort
                        :options="{ itemsPerPage: 5 }" v-if="item.interviews.length > 0">
                        <template v-slot:[`item.name`]="{ item }">
                          <v-card-text class="d-flex align-center">
                            {{ item.name | slice40Characters }}
                            <v-tooltip top v-if="item.name.length >= 40">
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon class="ml-2 more-info-btn" :ripple="false"> <v-icon size="20"
                                    color="primary">mdi-information</v-icon> </v-btn>
                              </template>
                              <span>{{ item.name }}</span>
                            </v-tooltip>
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                          <v-card-text class="d-flex align-center">
                            {{ item.description | slice20Characters }}
                            <v-tooltip top v-if="item.description.length >= 20">
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon class="ml-2 more-info-btn" :ripple="false"> <v-icon size="20"
                                    color="primary">mdi-information</v-icon> </v-btn>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          {{ item.date | formatDate }}
                        </template>
                        <template v-slot:[`item.mmr`]="{ item }">
                          {{ item.mmr.name }}
                        </template>
                        <template v-slot:[`item.competencies`]="{ item }">
                          <v-card-text class="d-flex align-center">
                            {{ item.competencies.length }}
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon class="ml-2 more-info-btn" :ripple="false"> <v-icon size="20"
                                    color="primary">mdi-information</v-icon> </v-btn>
                              </template>
                              <span>
                                <v-list color="transparent">
                                  <v-list-item v-for="(competence, index) in item.competencies" :key="index">
                                    <v-list-item-icon>
                                      <v-icon class="white--text">mdi-circle-small</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title class="white--text">{{ competence.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </span>
                            </v-tooltip>
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.is_ia_interviewer`]="{ item }">
                          <v-card-text v-if="item.is_ia_interviewer" class="d-flex align-end"><v-icon size="20"
                              color="primary">mdi-robot</v-icon>&nbsp;&nbsp;Avatar</v-card-text>
                          <v-card-text v-else class="d-flex align-end"><v-icon size="20"
                              color="primary">mdi-account</v-icon>&nbsp;&nbsp;{{ item.interviewer_id }}</v-card-text>
                        </template>
                        <template v-slot:[`item.interview_status`]="{ item }">
                          <v-chip :color="getColorInterviewStatus(item.interview_status)">
                            {{ item.interview_status }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="resendInterviewMail(item)" class="mr-2"
                                :disabled="item.interview_status !== 'CREATED'">
                                <v-icon size="20" color="primary">mdi-send</v-icon> </v-btn>
                            </template>
                            <span>Reenviar email</span>
                          </v-tooltip>

                          <v-tooltip top v-if="!item.is_ia_interviewer">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="openAccessInterview(item)" class="mr-2"
                                :disabled="item.interview_status === 'COMPLETED' || item.interview_status === 'ANALYSED'">
                                <v-icon size="20" color="primary">mdi-play</v-icon> </v-btn>
                            </template>
                            <span>Acceder</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="openEditInterview(item)" class="mr-2"
                                :disabled="item.interview_status === 'COMPLETED'"> <v-icon size="20"
                                  color="primary">mdi-square-edit-outline</v-icon> </v-btn>
                            </template>
                            <span>Editar</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="openDeleteInterview(item)"> <v-icon size="20"
                                  color="error">mdi-delete</v-icon> </v-btn>
                            </template>
                            <span>Borrar</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" icon @click="showAnalysis(item)" color="primary"
                                :disabled="item.interview_status !== 'ANALYSED'"> <v-icon size="20">mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>Ver análisis</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                      <v-alert v-else outlined type="info" color="info" class="mb-0">
                        El candidato no tiene videoentrevistas programadas.
                      </v-alert>
                    </td>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openCV(item)"
                          :disabled="item.cv_extraction_status !== 'COMPLETED'"> <v-icon size="20"
                            color="primary">mdi-eye</v-icon> </v-btn>
                      </template>
                      <span>Ver CV</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openCreateInterview(item)" color="primary"> <v-icon
                            size="20">mdi-video-plus</v-icon> </v-btn>
                      </template>
                      <span>Crear videoentrevista</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openDeleteCandidate(item)"> <v-icon size="20"
                            color="error">mdi-delete</v-icon> </v-btn>
                      </template>
                      <span>Eliminar candidato</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert class="mt-4" v-if="messageCV" outlined :color="successfullCV ? 'success' : 'error'"
              :icon="successfullCV ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
              {{ messageCV }}
            </v-alert>
            <v-alert class="mt-4" v-if="messageCompetencies" outlined
              :color="successfullCompetencies ? 'success' : 'error'"
              :icon="successfullCompetencies ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
              {{ messageCompetencies }}
            </v-alert>
            <v-alert class="mt-4" v-if="messageMMR" outlined :color="successfullMMR ? 'success' : 'error'"
              :icon="successfullMMR ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
              {{ messageMMR }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createInterviewDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createInterviewForm">
          <v-card-title>
            <h5>Crear videoentrevista</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Nombre *" v-model="interview.name" :rules="rules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="interview.description" :rules="rules">
                    <template v-slot:label>
                      <div>
                        Descripción *
                      </div>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select :items="mmrs" item-text="name" item-value="id" label="MMR *" v-model="interview.mmr_id"
                    :rules="rules"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete :items="competencies" item-text="name" item-value="id" label="Competencias *"
                    v-model="interview.competencies_ids" multiple :rules="rulesMultiple"></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="interview.is_ia_interviewer">
                    <template v-slot:label>Tipo de entrevistador *</template>
                    <v-radio v-for="(option, index) in optionsInterviewer" :key="index" :label="option.label"
                      :value="option.value"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-menu v-model="menuDate" :close-on-content-click="true" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="interview.is_ia_interviewer ? 'Fecha máxima de realización *' : 'Fecha *'" readonly
                            v-on="on" v-model="interview.day" :rules="rules"></v-text-field>
                        </template>
                        <v-date-picker locale="es-es" v-model="interview.day" :min="now"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-menu v-model="menuTime" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :label="interview.is_ia_interviewer ? 'Hora máxima de realización *' : 'Hora *'"
                            readonly v-on="on" v-model="interview.hour" :rules="rules"></v-text-field>
                        </template>
                        <v-time-picker v-model="interview.hour" format="24hr" elevation="15"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert v-if="messageDialog" outlined :color="successfullDialog ? 'success' : 'error'"
                :icon="successfullDialog ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                {{ messageDialog }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateInterview" class="cancel-modal"> Cancelar </v-btn>
            <v-btn @click="createInterview" class="ok-modal" v-if="!creating"> Crear </v-btn>
            <v-btn @click="createInterview" class="ok-modal" v-else disabled> <v-progress-circular indeterminate
                color="primary"></v-progress-circular> </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editInterviewDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editInterviewForm">
          <v-card-title>
            <h5>Editar videoentrevista</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Nombre *" v-model="itemToEditInterview.name" :rules="rules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="itemToEditInterview.description" :rules="rules">
                    <template v-slot:label>
                      <div>
                        Descripción *
                      </div>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select :items="mmrs" item-text="name" item-value="id" label="MMR *"
                    v-model="itemToEditInterview.mmr_id" :rules="rules"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete :items="competencies" item-text="name" item-value="id" label="Competencias *"
                    v-model="itemToEditInterview.competencies_ids" multiple :rules="rulesMultiple"></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="itemToEditInterview.is_ia_interviewer">
                    <template v-slot:label>Tipo de entrevistador *</template>
                    <v-radio v-for="(option, index) in optionsInterviewer" :key="index" :label="option.label"
                      :value="option.value"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-menu v-model="menuDateEdit" :close-on-content-click="true" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="itemToEditInterview.is_ia_interviewer ? 'Fecha máxima de realización *' : 'Fecha *'"
                            readonly v-on="on" v-model="itemToEditInterview.day" :rules="rules"></v-text-field>
                        </template>
                        <v-date-picker locale="es-es" v-model="itemToEditInterview.day" :min="now"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-menu v-model="menuTimeEdit" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="itemToEditInterview.is_ia_interviewer ? 'Hora máxima de realización *' : 'Hora *'"
                            readonly v-on="on" v-model="itemToEditInterview.hour" :rules="rules"></v-text-field>
                        </template>
                        <v-time-picker v-model="itemToEditInterview.hour" format="24hr" elevation="15"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert v-if="messageDialogEditInterview" outlined :color="successfullDialog ? 'success' : 'error'"
                :icon="successfullDialog ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                {{ messageDialogEditInterview }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelEditInterview" class="cancel-modal"> Cancelar </v-btn>
            <v-btn @click="editInterview" class="ok-modal" v-if="!updating"> Guardar cambios </v-btn>
            <v-btn @click="editInterview" class="ok-modal" v-else disabled> <v-progress-circular indeterminate
                color="primary"></v-progress-circular> </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteInterviewDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Borrar videoentrevista</h5>
        </v-card-title>
        <v-card-text>
          <p>¿Realmente desea borrar la videoentrevista seleccionada?<br>Si es así, pulse Confirmar.</p>
          <v-textarea v-model="reason" :rules="rules">
            <template v-slot:label>
              <div>
                Motivo *
              </div>
            </template>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="deleteInterviewDialog = false" class="cancel-modal"> Cancelar </v-btn>

          <template v-if="!deletingInterview">
            <v-btn @click="deleteInterview" class="ok-modal" v-if="reason !== ''"> Confirmar </v-btn>
            <v-btn @click="deleteInterview" class="ok-modal" disabled v-else> Confirmar </v-btn>
          </template>
          <v-btn @click="deleteInterview" class="ok-modal" v-else disabled> <v-progress-circular indeterminate
              color="primary"></v-progress-circular> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Borrar candidato</h5>
        </v-card-title>
        <v-card-text>
          <p>¿Realmente desea borrar el candidato seleccionado?<br>Si es así, pulse Confirmar.</p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false" class="cancel-modal"> Cancelar </v-btn>
          <v-btn @click="deleteCandidate" class="ok-modal" v-if="!deletingCandidate"> Confirmar </v-btn>
          <v-btn @click="deleteCandidate" class="ok-modal" v-else disabled> <v-progress-circular indeterminate
              color="primary"></v-progress-circular> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="accessInterviewDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Iniciar videoentrevista</h5>
        </v-card-title>
        <v-card-text>
          <p>No es posible iniciar la videoentrevista hasta diez minutos antes de la fecha y hora programadas ({{
                  accessDate
                }}).</p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="accessInterviewDialog = false" class="ok-modal"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CVsDataService from "@/services/CVsDataService";
import CompetenceDataService from "@/services/CompetenceDataService";
import InterviewDataService from "@/services/InterviewDataService";
import MmrDataService from "@/services/MmrDataService";
import SocketioService from '@/services/socketioService.js'
import { shallowRef } from 'vue'

export default {
  name: 'CVList',
  data() {
    return {
      cvs: [],
      competencies: [],
      mmrs: [],
      headers: [
        { text: 'Candidato', value: 'candidate' },
        { text: 'Clave', value: 'key' },
        { text: 'Número de videoentrevistas', value: 'interviews.length' },
        { text: 'Estado análisis CV', value: 'cv_extraction_status' },
        { text: 'Fecha de subida', value: 'date' },
        { text: 'Actions', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      expanded: [],
      headersInterviews: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Fecha y hora', value: 'date' },
        { text: 'MMR', value: 'mmr' },
        { text: 'Competencias', value: 'competencies' },
        { text: 'Entrevistador', value: 'is_ia_interviewer' },
        { text: 'Estado', value: 'interview_status' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      itemToDelete: null,
      deleteDialog: false,
      itemToDeleteInterview: null,
      deleteInterviewDialog: false,
      itemToCreateInterview: null,
      createInterviewDialog: false,
      itemToEditInterview: {},
      editInterviewDialog: false,
      accessInterviewDialog: false,
      itemToAccessInterview: {},
      ableToStartInterview: false,
      accessDate: '',
      loading: false,
      messageCV: '',
      messageCompetencies: '',
      messageMMR: '',
      messageCreateInterview: '',
      messageDeleteCandidate: '',
      messageEditInterview: '',
      messageDeleteInterview: '',
      messageRegisterInterview: '',
      messageResendMail: '',
      successfullCV: false,
      successfullCompetencies: false,
      successfullMMR: false,
      successfullCreateInterview: false,
      successfullDeleteCandidate: false,
      successfullEditInterview: false,
      successfullDeleteInterview: false,
      successfullRegisterInterview: false,
      successfullResendMail: false,
      messageDialog: '',
      messageDialogEditInterview: '',
      successfullDialog: false,
      optionsInterviewer: [{ label: 'Entrevistador', value: false }, { label: 'Avatar', value: true }],
      rules: [v => !!v || 'Campo obligatorio'],
      rulesMultiple: [v => v.length > 0 || 'Campo obligatorio'],
      menuDate: false,
      menuTime: false,
      menuDateEdit: false,
      menuTimeEdit: false,
      interview: { name: '', description: '', mmr_id: null, competencies_ids: [], is_ia_interviewer: false, day: null, hour: null },
      now: null,
      nowTZ: null,
      nowSpaceSeparator: null,
      reason: '',
      creating: false,
      updating: false,
      deletingCandidate: false,
      deletingInterview: false
    }
  },
  components: {
  },
  created() {
  },
  async mounted() {
    this.getDateTimeNow()
    await this.getAllCompetencies()
    await this.getAllMmrs()
    await this.getAllCVs()
  },
  beforeUnmount() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getDateTimeNow() {
      let tmpDate = new Date()

      let year = tmpDate.getFullYear()
      let month = String(tmpDate.getMonth() + 1).padStart(2, '0')
      let day = String(tmpDate.getDate()).padStart(2, '0')
      let hours = String(tmpDate.getHours()).padStart(2, '0')
      let minutes = String(tmpDate.getMinutes()).padStart(2, '0')

      this.now = `${year}-${month}-${day}T${hours}:${minutes}`
      this.nowSpaceSeparator = `${year}-${month}-${day} ${hours}:${minutes}`
      this.nowTZ = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`
    },
    getAllCVs() {
      this.loading = true
      CVsDataService.findAll().then(
        response => {
          if (response.status == 200) {
            this.cvs = response.data.candidates

            this.cvs.forEach(cv => {
              cv.interviews.forEach(interview => {
                interview.competencies = this.competencies.filter(competence => interview.competencies.includes(competence.id));
                interview.mmr = this.mmrs.find(mmr => mmr.id === interview.mmr);
                interview.ableToStartInterviewLater = this.checkStartInterviewLater(interview.date)
              })
            })

            this.cvs.sort((a, b) => {
              const dateA = new Date(a.date)
              const dateB = new Date(b.date)
              return dateB - dateA
            })
          } else {
            this.successfullCV = false
            this.messageCV = 'Ha ocurrido un error obteniendo el listado de CVs. Por favor, inténtelo de nuevo.'
          }

          this.loading = false
        },
        error => {
          this.successfullCV = false
          this.messageCV = 'Ha ocurrido un error obteniendo el listado de CVs. Por favor, inténtelo de nuevo.'
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
          this.loading = false
        }
      )
    },
    getAllCompetencies() {
      CompetenceDataService.findAll().then(
        response => {
          if (response.status == 200) this.competencies = response.data
          else {
            this.successfullCompetencies = false
            this.messageCompetencies = 'Ha ocurrido un error obteniendo el listado de competencias. Por favor, inténtelo de nuevo.'
          }
        },
        error => {
          this.successfullCompetencies = false
          this.messageCompetencies = 'Ha ocurrido un error obteniendo el listado de competencias. Por favor, inténtelo de nuevo.'
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    getAllMmrs() {
      MmrDataService.findAll().then(
        response => {
          if (response.status == 200) this.mmrs = response.data
          else {
            this.successfullMMR = false
            this.messageMMR = 'Ha ocurrido un error obteniendo el listado de MMRs. Por favor, inténtelo de nuevo.'
          }
        },
        error => {
          this.successfullMMR = false
          this.messageMMR = 'Ha ocurrido un error obteniendo el listado de MMRs. Por favor, inténtelo de nuevo.'
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    getColor(item) {
      if (item === 'PENDING') return 'accent'
      else if (item === 'PROCESSING') return 'info'
      else if (item === 'COMPLETED') return 'success'
      else return 'error'
    },
    getColorInterviewStatus(item) {
      if (item === 'CREATED') return 'info'
      else if (item === 'COMPLETED') return 'success'
      else if (item === 'ANALYSED') return 'warning' // TODO --> Cambiar este color por uno de verdad
      else return 'error'
    },
    openCV(cv) {
      this.$router.push({ name: 'cv results', params: { cv: cv } });
    },
    openCreateInterview(candidate) {
      if (this.$refs.createInterviewForm !== undefined) this.$refs.createInterviewForm.reset()
      this.createInterviewDialog = true
      this.itemToCreateInterview = { ...candidate }
    },
    createInterview() {
      this.successfullDialog = true
      this.messageDialog = ''
      if (this.$refs.createInterviewForm.validate()) {
        let dateTime = this.interview.day + 'T' + this.interview.hour
        if (this.checkValidDateTime(dateTime)) {
          this.interview.date = this.getDateTimeTZFormat(this.interview.day, this.interview.hour)
          this.interview.candidate_id = this.itemToCreateInterview.candidate_id
          this.interview.interviewer_id = '98634592835' //Hardcodeado con valor aleatorio

          this.creating = true

          InterviewDataService.createInterview(this.interview).then(
            response => {
              if (response.status == 200) {
                this.getAllCVs()
                this.successfullCreateInterview = true
                this.messageCreateInterview = 'Videoentrevista creada con éxito.'
                this.createInterviewDialog = false
              } else {
                this.successfullCreateInterview = false
                this.messageCreateInterview = 'Ha ocurrido un error creando la videoentrevista. Por favor, inténtelo de nuevo más tarde.'
                this.createInterviewDialog = false
              }
              this.creating = false
            },
            error => {
              this.successfullCreateInterview = false
              this.messageCreateInterview = 'Ha ocurrido un error creando la videoentrevista. Por favor, inténtelo de nuevo más tarde.'
              this.createInterviewDialog = false
              console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())

              this.creating = false
            }
          )
        } else {
          this.successfullDialog = false
          this.messageDialog = "La fecha y hora especificada debe ser posterior a la fecha y hora actual."
        }
      }
    },
    cancelCreateInterview() {
      this.createInterviewDialog = false;
    },
    openDeleteCandidate(candidate) {
      this.deleteDialog = true
      this.itemToDelete = { ...candidate }
    },
    deleteCandidate() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingCandidate = true
        CVsDataService.deleteCV(this.itemToDelete.id).then(
          response => {
            if (response.status == 200) {
              this.getAllCVs()
              this.successfullDeleteCandidate = true
              this.messageDeleteCandidate = 'CV borrado con éxito.'
            } else {
              this.successfullDeleteCandidate = false
              this.messageDeleteCandidate = 'Ha ocurrido un error borrando el CV. Por favor, inténtelo más tarde.'
            }

            this.deleteDialog = false
            this.deletingCandidate = false
          },
          error => {
            this.successfullDeleteCandidate = false
            this.messageDeleteCandidate = 'Ha ocurrido un error borrando el CV. Por favor, inténtelo más tarde.'
            console.error((error.response && error.response.data) ||
              error.message ||
              error.toString())

            this.deleteDialog = false
            this.deletingCandidate = false
          }
        )
      } else {
        this.successfullDeleteCandidate = false
        this.messageDeleteCandidate = 'Ha ocurrido un error borrando el CV. Por favor, inténtelo más tarde.'
        this.deleteDialog = false
      }
    },
    openEditInterview(interview) {
      this.editInterviewDialog = true
      this.itemToEditInterview = { ...interview }
      this.itemToEditInterview.competencies_ids = this.itemToEditInterview.competencies.map(competence => competence.id)
      this.itemToEditInterview.mmr_id = this.itemToEditInterview.mmr.id
      let tmpDayHour = this.getDayHourFromTZ(this.itemToEditInterview.date)
      this.itemToEditInterview.day = tmpDayHour[0]
      this.itemToEditInterview.hour = tmpDayHour[1]
      this.itemToEditInterview.candidate_id = this.expanded[0].candidate_id
    },
    editInterview() {
      this.successfullDialog = true
      this.messageDialogEditInterview = ''
      if (this.$refs.editInterviewForm.validate()) {
        let dateTime = this.itemToEditInterview.day + 'T' + this.itemToEditInterview.hour
        if (this.checkValidDateTime(dateTime)) {
          this.itemToEditInterview.date = this.getDateTimeTZFormat(this.itemToEditInterview.day, this.itemToEditInterview.hour)
          this.itemToEditInterview.interviewer_id = '98634592835' //Hardcodeado con valor aleatorio

          this.updating = true

          InterviewDataService.editInterview(this.itemToEditInterview).then(
            response => {
              if (response.status == 200) {
                this.getAllCVs()
                this.successfullEditInterview = true
                this.messageEditInterview = 'Videoentrevista editada con éxito.'
                this.editInterviewDialog = false
              } else {
                this.successfullEditInterview = false
                this.messageEditInterview = 'Ha ocurrido un error editando la videoentrevista. Por favor, inténtelo de nuevo más tarde.'
                this.editInterviewDialog = false
              }

              this.updating = false
            },
            error => {
              this.successfullEditInterview = false
              this.messageEditInterview = 'Ha ocurrido un error editando la videoentrevista. Por favor, inténtelo de nuevo más tarde.'
              this.editInterviewDialog = false
              console.error((error.response && error.response.data) ||
                error.message ||
                error.toString())

              this.updating = false
            }
          )
        } else {
          this.successfullDialog = false
          this.messageDialogEditInterview = "La fecha y hora especificada debe ser posterior a la fecha y hora actual."
        }
      }
    },
    cancelEditInterview() {
      this.editInterviewDialog = false;
    },
    openDeleteInterview(interview) {
      this.deleteInterviewDialog = true
      this.itemToDeleteInterview = { ...interview }
    },
    deleteInterview() {
      if (this.itemToDeleteInterview.id !== undefined) {
        this.deletingInterview = true
        InterviewDataService.deleteInterview(this.itemToDeleteInterview.id, this.itemToDeleteInterview.candidate_id, this.itemToDeleteInterview.mmr.id, this.reason).then(
          response => {
            if (response.status == 200) {
              this.getAllCVs()
              this.reason = ''
              this.successfullDeleteInterview = true
              this.messageDeleteInterview = 'Videoentrevista borrado con éxito.'
            } else {
              this.reason = ''
              this.successfullDeleteInterview = false
              this.messageDeleteInterview = 'Ha ocurrido un error borrando la videoentrevista. Por favor, inténtelo más tarde.'
            }

            this.deleteInterviewDialog = false
            this.deletingInterview = false
          },
          error => {
            this.reason = ''
            this.successfullDeleteInterview = false
            this.messageDeleteInterview = 'Ha ocurrido un error borrando la videoentrevista. Por favor, inténtelo más tarde.'
            console.error((error.response && error.response.data) ||
              error.message ||
              error.toString())

            this.deleteInterviewDialog = false
            this.deletingInterview = false
          }
        )
      } else {
        this.successfullDeleteInterview = false
        this.messageDeleteInterview = 'Ha ocurrido un error borrando la videoentrevista. Por favor, inténtelo más tarde.'
        this.deleteInterviewDialog = false
      }
    },
    resendInterviewMail(interview) {
      InterviewDataService.resendMail(interview.id).then(
        response => {
          if (response.status == 200) {
            this.successfullResendMail = true
            this.messageResendMail = 'Email enviado con éxito.'
          } else {
            this.successfullResendMail = false
            this.messageResendMail = 'Ha ocurrido un error enviando el email. Por favor, inténtelo más tarde.'
          }
        },
        error => {
          this.successfullResendMail = false
          this.messageResendMail = 'Ha ocurrido un error enviando el email. Por favor, inténtelo más tarde.'
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    openAccessInterview(interview) {
      this.itemToAccessInterview = { ...interview }

      let tmpDayHour = this.getDayHourFromTZ(this.itemToAccessInterview.date)
      this.accessDate = tmpDayHour[0] + ' ' + tmpDayHour[1]

      if (!this.checkStartInterview(this.itemToAccessInterview.date)) this.accessInterviewDialog = true
      else this.startInterview()
    },
    checkStartInterview(interviewDate) {
      let nowTZ = new Date(this.nowTZ)
      interviewDate = new Date(interviewDate)

      interviewDate.setMinutes(interviewDate.getMinutes() - 10);

      if (nowTZ.getTime() >= interviewDate.getTime()) return true
      else return false
    },
    checkStartInterviewLater(interviewDate) {
      interviewDate = this.$options.filters.formatDate(interviewDate);

      let now = new Date(this.nowSpaceSeparator)
      interviewDate = new Date(interviewDate)

      interviewDate.setHours(interviewDate.getHours() + 1)

      if (now.getTime() <= interviewDate.getTime()) return true
      else return false
    },
    startInterview() {
      InterviewDataService.registerCall(this.itemToAccessInterview.candidate_id, this.itemToAccessInterview.interviewer_id, this.itemToAccessInterview.id).then(
        response => {
          if (response.status == 200) {
            this.$router.push({ name: 'access interview', params: { interview: this.itemToAccessInterview } });
            SocketioService.createCallRoom(this.$socket, response.data.id)
          } else {
            this.successfullRegisterInterview = false
            this.messageRegisterInterview = 'Ha ocurrido un error registrando la videoentrevista. Por favor, inténtelo más tarde.'
          }
        },
        error => {
          this.successfullRegisterInterview = false
          this.messageRegisterInterview = 'Ha ocurrido un error registrando la videoentrevista. Por favor, inténtelo más tarde.'
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    showAnalysis(item) {
      console.log('showAnalysis: ', item)
    },
    checkValidDateTime(dateTime) {
      let stringDateTime = new Date(dateTime);
      let stringNow = new Date(this.now);

      if (stringNow.getTime() === stringDateTime.getTime() || stringNow.getTime() > stringDateTime.getTime()) return false
      else return true
    },
    getDateTimeTZFormat(date, time) {
      let [year, month, day] = date.split('-').map(Number)
      let [hours, minutes] = time.split(':').map(Number)
      let dateTime = new Date(year, month - 1, day, hours, minutes)

      return dateTime.toISOString()
    },
    getDayHourFromTZ(tzDate) {
      let tzDateSplitted = this.$options.filters.formatDate(tzDate).split(' ');
      return [tzDateSplitted[0], tzDateSplitted[1].substr(0, 5)]
    }
  }
}
</script>