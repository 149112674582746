import http from "../http-common";

const API_URL_PATH = 'utils/competencies';

class CompetenceDataService {

  findAll() {
    return http.get(API_URL_PATH)
  }
}

export default new CompetenceDataService();