<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-row class="fill-height" no-gutters>
                    <v-col class="col col-12 col-sm-12 col-md-12">
                        <v-row>
                            <v-col class="col col-12">
                                <v-card-title>
                                    <h1>¡Error!</h1>
                                </v-card-title>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col col-12">
                                <p class="ml-4">En estos momentos la página no está disponible. Inténtelo de nuevo más
                                    tarde.</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: 'ErrorPage'
}
</script>

<style scoped>
.row {
    margin: 0 !important;
}
</style>