import http from "../http-common";

const API_URL_PATH = 'utils/mmrs';

class MmrDataService {
  findAll() {
    return http.get(API_URL_PATH)
  }
}

export default new MmrDataService();