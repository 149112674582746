<template>
    <v-container fluid fill-height>
        <v-layout>
            <v-flex>
                <v-row class="fill-height" no-gutters>
                    <v-col class="col col-12 col-sm-12 col-md-12">
                        <v-row>
                            <v-col class="col col-12">
                                <v-card-title>
                                    <h1>Resultado de la entrevista</h1>
                                </v-card-title>
                                <!-- <v-card-text>
                    <v-row>
                      <v-col cols="1">
                      </v-col>
                      <v-col cols="10">
                      </v-col>
                    </v-row>
                  </v-card-text> -->
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-alert class="mt-4" v-if="message" outlined :color="successfull ? 'success' : 'error'"
                    :icon="successfull ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
                    {{ message }}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    name: 'CVResults',
    data() {
        return {
            message: '',
            successfull: false
        }
    },
    computed: {
    },
    mounted() {
        this.getInterviewResults()
    },
    methods: {
        getInterviewResults() {
            if (this.$route.params.interview !== undefined && this.$route.params.interview !== null) {
                console.log('recuperar entrevista')
                // CVsDataService.getCV(this.$route.params.interview.candidate_id).then(
                //   response => {
                //     if (response.status == 200) {

                //     } else {
                //       this.successfull = false
                //       this.message = 'Ha ocurrido un error recuperando la información de la entrevista. Por favor, inténtelo más tarde.'
                //     }
                //   },
                //   error => {
                //     this.successfull = false
                //     this.message = 'Ha ocurrido un error recuperando la información de la entrevista. Por favor, inténtelo más tarde.'
                //     console.error((error.response && error.response.data) ||
                //       error.message ||
                //       error.toString())
                //   }
                // )
            } else {
                this.$router.push('/cv-list');
            }
        }
    }
}
</script>