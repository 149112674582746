<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <h1>Acceso a la videoentrevista</h1>
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-6">
                <v-row>
                  <h6>Nombre: </h6>
                  <v-col class="col col-12">
                    <p>{{ interview.name }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>Descripción: </h6>
                  <v-col class="col col-12">
                    <p>{{ interview.description }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>MMR: </h6>
                  <v-col class="col col-12">
                    <p>{{ interview.mmr.name }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>Competencias: </h6>
                  <v-col class="col col-12">
                    <v-list color="transparent">
                      <v-list-item v-for="(competence, index) in interview.competencies" :key="index">
                        <v-list-item-icon>
                          <v-icon>mdi-circle-small</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ competence.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-6">
                <v-row>
                  <h6>Entrarás como: </h6>
                  <v-col class="col col-12">
                    <p>{{ candidateName }}</p>
                  </v-col>
                  <v-col class="col col-12">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)"> <v-icon size="20"
                            color="primary">mdi-microphone</v-icon> </v-btn>
                      </template>
                      <span>Desactivar micrófono</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)"> <v-icon size="20"
                            color="primary">mdi-microphone-off</v-icon> </v-btn>
                      </template>
                      <span>Activar micrófono</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)"> <v-icon size="20"
                            color="primary">mdi-video</v-icon> </v-btn>
                      </template>
                      <span>Desactivar cámara</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)"> <v-icon size="20"
                            color="primary">mdi-video-off</v-icon> </v-btn>
                      </template>
                      <span>Activar cámara</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <div>
                      <video ref="localVideo" id="localVideo" class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png" autoplay></video>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12 d-flex justify-center">
                <v-btn v-if="interviewStatus === 'COMPLETED' || interviewStatus === 'ANALYSED'" class="ok-modal"
                  @click="accessInterviewCandidateDialog = true" disabled> Entrevista cerrada </v-btn>
                <v-btn class="ok-modal" @click="accessInterviewCandidateDialog = true" v-else-if="!access" disabled>
                  Entrar </v-btn>
                <v-btn class="ok-modal" @click="accessInterviewCandidateDialog = true" v-else> Entrar </v-btn>
                <v-btn v-bind="attrs" v-on="on" icon class="ml-2">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-icon size="20" color="primary" v-on="on">mdi-information</v-icon>
                    </template>
                    <span v-if="interviewStatus === 'COMPLETED' || interviewStatus === 'ANALYSED'">La entrevista ha
                      finalizado.</span>
                    <span v-else-if="!access">No se puede entrar en la entrevista hasta que el entrevistador acceda a
                      ella.</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>


      <v-dialog v-model="accessInterviewCandidateDialog" max-width="550" min-height="550">
        <v-card>
          <v-card-title>
            <h5>Entrar a la videoentrevista</h5>
          </v-card-title>
          <v-card-text>
            <p>El contenido de la llamada será almacenado con fines analíticos. Si desea acceder, pulse Ok.</p>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="accessInterviewCandidateDialog = false" class="cancel-modal"> Cancelar </v-btn>
            <v-btn @click="enterInterview" class="ok-modal"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import CVsDataService from "@/services/CVsDataService"
import SocketioService from '@/services/socketioService.js'
import UserMediaController from '@/services/UserMedia.js'
import InterviewDataService from "@/services/InterviewDataService"
import CandidateDataService from "@/services/CandidateDataService"

export default {
  name: 'AccessInterview',
  data() {
    return {
      candidateName: '',
      cvs: [],
      microActivated: true,
      cameraActivated: true,
      selfUserStream: null,
      candidateId: null,
      interview: { name: "prueba", description: "Prueba", mmr: { name: "prueba" }, competencies: [] },
      accessInterviewCandidateDialog: false,
      interviewStatus: null,
      access: true
    }
  },
  components: {
  },
  created() {
    SocketioService.emisor.$on('callRoomResponse', async (data) => {
      this.$socket.callRoom = data.callId
      this.access = data.canAccess
    })
  },
  async mounted() {
    if (this.$route.query.username === undefined || this.$route.query.candidate_id === undefined || this.$route.query.interview_id === undefined || this.$route.query.interviewer_id === undefined || this.$route.query.email === undefined)
      this.$router.push('/error')
    else {
      const { username, candidate_id, interview_id, interviewer_id, email } = this.$route.query
      this.candidateName = username

      localStorage.removeItem('isCandidate')
      localStorage.removeItem('query')

      await InterviewDataService.getInterviewInfo(interview_id).then(async (res) => {
        if (res.status === 200) {
          this.interview = res.data.interview
          this.interviewStatus = res.data.status
        }
      }).catch(err => console.error(err))

      await CandidateDataService.getCandidateInfo(candidate_id, interview_id, username, email).then((res) => {
        if (res.status === 200) {
          this.candidateId = res.data.candidate_id
          SocketioService.sendCandidateId(this.$socket, this.candidateId)

          InterviewDataService.registerCall(this.candidateId, interviewer_id, interview_id).then((res) => {
            if (res.status === 200)
              SocketioService.createCallRoom(this.$socket, res.data.id)
          })
        }

      })

      UserMediaController.getUserMedia(function (stream) {
        if (!stream) {
          stream = UserMediaController.getPlaceholderStream();
        }

        const localVideo = document.getElementById('localVideo')
        localVideo.srcObject = new MediaStream(stream.getVideoTracks());

        // Apply options to stream
        UserMediaController.setMediaStreamVideoEnabled(stream, this.cameraActivated);
        UserMediaController.setMediaStreamAudioEnabled(stream, this.microActivated);

        // Get the stream
        this.selfUserStream = stream;
      }.bind(this))
    }
  },
  beforeUnmount() {
  },
  beforeRouteLeave(to, from, next) {
    if (this.selfUserStream) UserMediaController.stopStream(this.selfUserStream)
    this.selfUserStream = null
    next()
  },
  methods: {
    async getAllCVs() {
      await CVsDataService.findAll().then(
        async response => {
          if (response.status == 200) {
            this.cvs = response.data.candidates
          }
        },
        error => {
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    enableAudio(enabled) {
      this.microActivated = enabled;
      UserMediaController.setMediaStreamAudioEnabled(this.selfUserStream, enabled)
    },
    enableVideo(enabled) {
      this.cameraActivated = enabled;
      UserMediaController.setMediaStreamVideoEnabled(this.selfUserStream, enabled)
    },
    enterInterview() {
      this.$router.push({ name: 'interview', params: { interview: this.interview, video: this.cameraActivated, audio: this.microActivated, interviewerName: 'Interviewer', candidateName: this.candidateName, isCandidate: true, redirectParams: this.$route.query } });
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0 !important;
}
</style>