<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row class="pt-8 align-center justify-center">
                  <v-col v-for="(card, index) in cards" :key="index" cols="7" sm="5" md="3">
                    <v-card @click="openFunctionality(card.id)" class="mx-8">
                      <v-card-title>{{ card.title }}</v-card-title>
                      <v-card-text>{{ card.content }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SocketioService from '@/services/socketioService.js'

export default {
  name: 'LandingPage',
  data() {
    return {
      cards: [
        { id: 0, title: 'Subida de CVs', content: 'Permite la subida de CVs de candidatos' },
        { id: 1, title: 'Listado de CVs', content: 'Muestra la información de los CVs subidos.' }
      ]
    }
  },
  components: {
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
    SocketioService.disconnect(this.$socket)
  },
  methods: {
    openUploadCvView() {
      this.$router.push('/upload-cv');
    },
    openCvList() {
      this.$router.push('/cv-list');
    },
    openFunctionality(id) {
      if (id == 0) {
        this.openUploadCvView()
      } else if (id == 1) {
        this.openCvList()
      }
    }
  }
}
</script>
