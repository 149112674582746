<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <h1>Subida de CVs</h1>
                </v-card-title>
                <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="true" :deletable="true" :meta="true"
                  :disabled="fileReceived" :accept="'application/pdf'" :maxSize="'10MB'" :editable="false"
                  :helpText="'Seleccione archivos PDF para subir sus CVs (sin comprimir)'" :errorText="{
                  type: 'Extensión de archivo errónea. Por favor, suba sólo archivos .pdf (sin comprimir).',
                  size: 'El tamaño máximo por archivo es de 10MB.',
                }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)" @delete="fileDeleted($event)"
                  v-model="fileRecords"></VueFileAgent>
              </v-col>
            </v-row>
            <v-row v-if="!uploadDisactive">
              <v-col class="col col-12">
                <v-card-title>
                  <h3>Información personal</h3>
                </v-card-title>
                <v-data-table :headers="headersPersonalInformation" :items="fileRecordsForUpload"
                  :options="{ itemsPerPage: 5 }" dense v-if="fileRecordsForUpload.length > 0">
                  <template v-slot:[`item.name`]="{ item }">
                    <v-card-text class="d-flex align-center">
                      {{ item.name() }}
                    </v-card-text>
                  </template>
                  <template v-slot:[`item.username`]="{ item }">
                    <v-text-field placeholder="Introduce el nombre de usuario" v-model="item.username"></v-text-field>
                  </template>
                  <template v-slot:[`item.email`]="{ item }">
                    <v-text-field placeholder="Introduce el email" v-model="item.email"></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert class="mt-4" v-if="message" outlined :color="successfull ? 'success' : 'error'"
              :icon="successfull ? 'mdi-check-circle' : 'mdi-alert'" dismissible>
              {{ message }}&nbsp;&nbsp;<v-btn @click="goCVList()" class="ml-4" text> Ir al listado de
                CVs</v-btn>
            </v-alert>
            <v-row class="mb-2" :class="message ? 'mt-4' : 'mt-0'" style="text-align: center;">
              <v-col class="col col-12">
                <template v-if="!loading">
                  <v-btn @click="upload" v-if="!uploadDisactive" class="ok-modal">Subir {{ fileRecordsForUpload.length
                    }} archivo/s</v-btn>
                </template>
                <v-btn @click="upload" v-else class="ok-modal" disabled><v-progress-circular indeterminate
                    size="20"></v-progress-circular>&nbsp;&nbsp;Subiendo {{ fileRecordsForUpload.length }}
                  archivo/s...</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import CVsDataService from "@/services/CVsDataService";

export default {
  name: 'UploadCV',
  data() {
    return {
      fileReceived: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadDisactive: true,
      headersPersonalInformation: [
        { text: 'CV', value: 'name' },
        { text: 'Nombre de usuario *', value: 'username' },
        { text: 'Email *', value: 'email' },
      ],
      message: '',
      successfull: false,
      loading: false
    }
  },
  components: {
  },
  created() {
  },
  mounted() {
    if (this.$socket_id === undefined)
      this.$router.push('/');
  },
  beforeUnmount() {
  },
  methods: {
    upload() {
      this.loading = false
      this.successfull = true
      this.message = ''
      let candidates = []
      let cvs = []

      for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
        if (this.fileRecordsForUpload[i].username === undefined || this.fileRecordsForUpload[i].username.trim() === '' || this.fileRecordsForUpload[i].email === undefined || this.fileRecordsForUpload[i].email.trim() === '') {
          this.successfull = false
          this.message = 'Por favor, rellene todos los campos.'

          return
        } else if (!this.checkValidEmail(this.fileRecordsForUpload[i].email)) {
          this.successfull = false
          this.message = 'El campo email tiene que seguir el formato: xxxxxx@xxxx.xxx'
          return
        }

        candidates.push({ username: this.fileRecordsForUpload[i].username, email: this.fileRecordsForUpload[i].email, fileAssociated: i })
        cvs.push(this.fileRecordsForUpload[i].file)
      }

      if (this.successfull) {
        this.loading = true
        let formData = new FormData();
        formData.append("candidates", JSON.stringify(candidates));
        cvs.forEach(cv => {
          formData.append("cvs", cv, cv.name);
        })
        if (this.$socket_id !== undefined && this.$socket_id !== null && this.$socket_id !== '') formData.append("socketId", this.$socket_id)
        else formData.append("socketId", null)

        CVsDataService.upload(formData).then(response => {
          if (response.status == 200) {
            this.successfull = true
            this.message = 'CVs subidos con éxito.'
          } else {
            this.successfull = false
            this.message = 'Ha ocurrido un error subiendo los CVs. Por favor, inténtelo más tarde.'
          }

          this.fileReceived = false
          this.fileRecords = []
          this.fileRecordsForUpload = []
          this.uploadDisactive = true
          this.loading = false
        })
          .catch((error) => {
            this.successfull = false
            this.message = 'Ha ocurrido un error subiendo los CVs. Por favor, inténtelo más tarde.'
            this.fileReceived = false
            this.fileRecords = []
            this.fileRecordsForUpload = []
            this.uploadDisactive = true
            this.loading = false
          });
      }
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      if (validFileRecords.length > 0) {
        this.uploadDisactive = false;
        this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      } else this.uploadDisactive = true;
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else this.$refs.vueFileAgent.deleteFileRecord(fileRecord);

      this.uploadDisactive = this.fileRecordsForUpload.length <= 0 ? true : false
      if (this.fileRecordsForUpload.length <= 0) this.message = ''
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUpload.splice(i, 1);
      else this.deleteUploadedFile(fileRecord);

      this.uploadDisactive = this.fileRecordsForUpload.length <= 0 ? true : false
      if (this.fileRecordsForUpload.length <= 0) this.message = ''
    },
    checkValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    goCVList() {
      this.$router.push('/cv-list');
    },
  }
}
</script>