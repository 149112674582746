<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <h1>Acceso a la videoentrevista</h1>
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-6">
                <v-row>
                  <h6>Nombre: </h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.name }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>Descripción: </h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.description }}</p>
                  </v-col>
                </v-row>
                <v-row v-if="this.$route.params.interview.candidate_id !== undefined">
                  <h6>Candidato: </h6>
                  <v-col class="col col-12">
                    <p>{{ candidateName }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>MMR: </h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.mmr.name }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <h6>Competencias: </h6>
                  <v-col class="col col-12">
                    <v-list color="transparent">
                      <v-list-item v-for="(competence, index) in this.$route.params.interview.competencies"
                        :key="index">
                        <v-list-item-icon>
                          <v-icon>mdi-circle-small</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ competence.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-6">
                <v-row>
                  <h6>Entrarás como: </h6>
                  <v-col class="col col-12">
                    <!-- <p>{{ this.$route.params.interview.interviewer_id }}</p> -->
                    <p>Interviewer</p>
                  </v-col>
                  <v-col class="col col-12">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)"> <v-icon size="20"
                            color="primary">mdi-microphone</v-icon> </v-btn>
                      </template>
                      <span>Desactivar micrófono</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)"> <v-icon size="20"
                            color="primary">mdi-microphone-off</v-icon> </v-btn>
                      </template>
                      <span>Activar micrófono</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)"> <v-icon size="20"
                            color="primary">mdi-video</v-icon> </v-btn>
                      </template>
                      <span>Desactivar cámara</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)"> <v-icon size="20"
                            color="primary">mdi-video-off</v-icon> </v-btn>
                      </template>
                      <span>Activar cámara</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <div>
                      <video ref="localVideo" id="localVideo" class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png" autoplay muted></video>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12 d-flex justify-center">
                <v-btn class="ok-modal" @click="createAndEnterInterview"> Crear y entrar </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CVsDataService from "@/services/CVsDataService"
import SocketioService from '@/services/socketioService.js'
import UserMediaController from '@/services/UserMedia.js'

export default {
  name: 'AccessInterview',
  data() {
    return {
      candidateName: '',
      cvs: [],
      microActivated: true,
      cameraActivated: true,
      selfUserStream: null
    }
  },
  components: {
  },
  created() {
    SocketioService.emisor.$on('callRoomResponse', async (data) => {
      this.$socket.callRoom = data.callId
    })
  },
  async mounted() {
    if (this.$route.params.interview === undefined)
      this.$router.push('/cv-list');
    else if (this.$route.params.interview.candidate_id !== undefined) {
      localStorage.removeItem('isCandidate')
      localStorage.removeItem('query')
      await this.getAllCVs()
      this.candidateName = this.cvs.find(cv => cv.candidate_id === this.$route.params.interview.candidate_id).candidate
    }

    UserMediaController.getUserMedia(function (stream) {
      if (!stream) {
        stream = UserMediaController.getPlaceholderStream();
      }

      const localVideo = document.getElementById('localVideo')
      localVideo.srcObject = new MediaStream(stream.getVideoTracks());

      // Apply options to stream
      UserMediaController.setMediaStreamVideoEnabled(stream, this.cameraActivated);
      UserMediaController.setMediaStreamAudioEnabled(stream, this.microActivated);

      // Get the stream
      this.selfUserStream = stream;
    }.bind(this))
  },
  beforeUnmount() {
  },
  beforeRouteLeave(to, from, next) {
    if (this.selfUserStream) UserMediaController.stopStream(this.selfUserStream)
    this.selfUserStream = null
    next()
  },
  methods: {
    async getAllCVs() {
      await CVsDataService.findAll().then(
        async response => {
          if (response.status == 200) {
            this.cvs = response.data.candidates
          }
        },
        error => {
          console.error((error.response && error.response.data) ||
            error.message ||
            error.toString())
        }
      )
    },
    enableAudio(enabled) {
      this.microActivated = enabled;
      UserMediaController.setMediaStreamAudioEnabled(this.selfUserStream, enabled)
    },
    enableVideo(enabled) {
      this.cameraActivated = enabled;
      UserMediaController.setMediaStreamVideoEnabled(this.selfUserStream, enabled)
    },
    createAndEnterInterview() {
      this.$router.push({ name: 'interview', params: { interview: this.$route.params.interview, video: this.cameraActivated, audio: this.microActivated, interviewerName: 'Interviewer', candidateName: this.candidateName, isCandidate: false } });
    }
  }
}
</script>

<style scoped>
.row {
  margin: 0 !important;
}
</style>